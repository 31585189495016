import React from 'react'
import ScrollToHashElement from "@cascadia-code/scroll-to-hash-element";

const Footer = () => {
  return (
    <div className="text-gray-600 text-center w-full mx-auto mt-24">
        <ScrollToHashElement behavior="smooth" inline="center" block="center"/>
        <p className="my-4">Copyright © 2024 Cureva - All Rights Reserved.</p>
        <div className="flex flex-col md:flex-row text-center mx-auto w-fit gap-8 my-6">
            <a href="#services" className="border-b border-weird-yellow">Services</a>
            <a href="#contact" className="border-b border-weird-yellow">Contact</a>
            {/* <a href="#partners" className="border-b border-weird-yellow">Partners</a> */}
            <a href="#services" className="border-b border-weird-yellow">API</a>
        </div>
        <hr className="border border-gray-200 w-12 mt-12 mb-8 mx-auto"/>
        <p className="text-center text-sm md:text-md">Powered by the good will of the team at Cureva</p>
    </div>
  )
}

export default Footer