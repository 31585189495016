import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { useState } from 'react'
// import { Button } from "@/components/ui/button"
import Slider from 'react-infinite-logo-slider'

import section2 from "./assets/section2.webp"
import video from './assets/landing_clip.mp4'
import { CheckCircle, Sparkles } from 'lucide-react'

import dhcb from "./assets/dhcb.jpeg"
import logo from "./assets/logo.webp"

import tufts from "./assets/tufts.png"
import ptc from "./assets/ptc.png"
import google from "./assets/google.png"
import cmu from "./assets/cmu.jpeg"
import cmuAI from "./assets/cmu-ai.png"

import tuftsMed from "./assets/Tufts-Medicine.png"

import ContactForm from "./components/Contact/ConatctForm"
import { Directions, LinkedIn } from '@mui/icons-material';
import ScrollToHashElement from "@cascadia-code/scroll-to-hash-element";
// import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"


function goToQ() {
    window.open('https://q.cureva.co', '_blank'); // Replace with your desired URL
}
function goToMyPatients() {
    window.open('https://mypatients.cureva.co', '_blank'); // Replace with your desired URL
}
function goToClinicalMatch() {
    window.open('https://clinicalmatch.cureva.co', '_blank'); // Replace with your desired URL
}

function goToLibraries() {
    window.open('https://github.com/Cureva-public', '_blank'); // Replace with your desired URL
}


function Dashboard() {
  return (
    <section className="py-16 px-4 md:px-6 lg:px-8 bg-white">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-black mt-40 text-4xl mb-10">
          One more reason to list your Trial on ClinicalTrials.gov
        </h2>
        <div className="grid md:grid-cols-2 gap-8 mb-8">
          {[
            {
              title: "Legal and Policy Requirements",
              description: "Registration is required by laws and policies such as the Final Rule for FDAAA 801, 42 CFR Part 11, NIH Policy on Dissemination of NIH-funded Clinical Trial Information, and WHO International Clinical Trials Registry Platform."
            },
            {
              title: "Publication in Medical Journals",
              description: "The International Committee of Medical Journal Editors (ICMJE) Policy requires prospective registration to publish results from a clinical trial in medical journals."
            }
          ].map((reason, index) => (
            <div key={index} className="bg-orange-50 rounded-lg p-8 shadow-sm">
              <div className="flex flex-col items-start gap-4">
                <div className="w-12 h-12 bg-orange-100 rounded-full flex items-center justify-center">
                  <CheckCircle className="w-6 h-6 text-orange-600" />
                </div>
                <h3 className="text-xl font-medium text-gray-800">
                  {reason.title}
                </h3>
                <p className="text-gray-600">
                  {reason.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="bg-orange-50 rounded-lg p-8 shadow-sm border-2 border-orange-200">
          <div className="flex flex-col items-center gap-6 text-center">

            <div className="space-y-4">
              <div className="flex items-center justify-center gap-2">
                <div className="flex items-center justify-center w-10 h-10 bg-orange-100 rounded-full">
                  <Sparkles className="w-6 h-6 text-orange-600" />
                </div>
                <h3 className="text-2xl font-medium text-gray-800">
                  Introducing: Organic (AI-driven) Patient Recruitment
                </h3>
              </div>
              <p className="text-gray-600 max-w-2xl">
                When you list your trial with clinicaltrials.gov, Cureva's AI automatically starts matching patients with your clinical trial. Co-ordinators now claim their respective clinical trials with our team and our tech does the heavy-lifting.
              </p>
            </div>
            <button onClick={goToMyPatients} className="inline-flex items-center justify-center px-4 py-2 bg-orange-600 text-white rounded-full text-sm font-medium">
              Claim Trial Now
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

function LogoCarousel() {
    return (
        <Slider
            width="250px"
            duration={40}
            // pauseOnHover={true}
            blurBorders={false}
            blurBorderColor={'#fff'}
        >

            <Slider.Slide>
                <img src={ptc} alt="any3" className='w-36' />
            </Slider.Slide>
            <Slider.Slide>
                <img src={cmu} alt="any5" className='w-36' />
            </Slider.Slide>
            <Slider.Slide>
                <img src={dhcb} alt="any" className='w-36' />
            </Slider.Slide>
            <Slider.Slide>
                <img src={tuftsMed} alt="any2" className='w-36' />
            </Slider.Slide>
            <Slider.Slide>
                <img src={google} alt="any4" className='w-36' />
            </Slider.Slide>
            <Slider.Slide>
                <img src={cmuAI} alt="any5" className='w-36' />
            </Slider.Slide>

        </Slider>
    );
}

function App() {
    const [isOpen, setIsOpen] = useState(false)

    const handleEnded = (event) => {
        setTimeout(() => {
          event.target.play();
        }, 3000); // 5-second delay
      };
  return (
    <div className="App">
      <ScrollToHashElement behavior="smooth" inline="center" block="center"/>
      <Header/>
      <div className="p-10">
        <section>
          <h2 className="text-gray-500 text-4xl">Transforming Clinical Trials Recruitment using AI</h2>
          <h4 className="text-black mt-5 text-3xl">Our team is committed to making patient recruitment a problem of the past</h4>
          <a href="#services"><button className="bg-weird-yellow text-white py-3 px-8 font-expanded font-medium text-xl mt-6  rounded-full">Explore our solutions</button></a>
        </section>
        <section>
        <video className="w-full mt-24"  autoPlay muted
          loop={false} // Disable loop attribute since we handle it manually
          onEnded={handleEnded} // Call handleEnded when video ends
        >
            <source src={video} type="video/mp4"/>
        </video>
        <Dashboard/>
        </section>
        <p className="text-black mt-40 text-4xl">A team with proven track record</p>
        <LogoCarousel/>

        <section className="mt-40 w-full text-center">
          <p className="text-black mt-5 text-4xl mb-2">Welcome on board</p>
          <p className="text-center text-xl mb-2"> We are a service committed to optimizing the clinical trials recruitment process. We connect pharmas, CROs,<br/> hospital systems, and patients to accelerate research initiatives and optimize patient outcomes.</p>
          <a href="#contact"><button className="bg-white border border-puke hover:bg-weird-yellow text-puke hover:text-white py-3 px-8 font-expanded font-medium text-xl mt-6 rounded-full">Request a demo</button></a>
        </section>

        <p className="text-black mt-40 text-4xl">We are encouraging innovation by making our APIs available</p>
        <section id="services" className="text-center md:flex md:flex-row gap-12 mx-auto md:mx-12 mt-12 justify-around md:space-y-0 space-y-6">
            <button className="rounded-lg transition duration-500 ease-in-out hover:text-2xl md:mx-0 mx-auto text-3xl text-white bg-weird-yellow w-full md:w-1/3 h-80" onClick={goToClinicalMatch}>
                Integration API
            </button>
            <button className="rounded-lg transition duration-500 ease-in-out hover:text-2xl md:mx-0 mx-auto text-3xl text-white bg-weird-yellow w-full md:w-1/3 h-80" onClick={goToLibraries}>
                Integration Libraries
            </button>
        </section>

           {/* <button className="rounded-lg md:mx-0 mx-auto text-3xl  text-weird-yellow  bg-white w-full md:w-1/3 h-80" disabled>
            Coming soon
          </button> */}
          {/* <button className="rounded-lg md:mx-0 mx-auto text-3xl  weird-yellow-border text-weird-yellow  bg-white w-full md:w-1/3 h-80" disabled>
            Coming soon
          </button> */}
          {/* <button className="rounded-lg transition duration-500 ease-in-out hover:text-2xl md:mx-0 mx-auto text-3xl w-full md:w-1/3 text-white bg-weird-yellow h-80" onClick={goToQ}>
            The Q
          </button> */}

        {/* <section id="partners" className="text-center flex flex-col mt-14">
          <p className="text-xl mt-20 mb-12">Our partners</p>
          <div className="flex flex-row justify-around">
            <img className="w-50 h-28 mr-5" src={dhcb} alt="dhcb"/> */}
            {/* <img className="w-50 h-28 mr-5" src={tufts} alt="dhcb"/> */}
          {/* </div>
        </section> */}
        {/* <section className="mt-24 w-full text-center"> */}
          {/* <p className="md:text-lg text-xl">Discover Cureva</p> */}
          {/* <p className="text-[#5e5e5e] mt-4 leading-loose"> We are passionate about creating software products that help reshape today's world.<br/> Our team of experienced doctors and engineers is dedicated to delivering customized solutions that <br/>challenges convention. We believe there are a lot of tech out there that's lacking usecases.<br/> We inherently believe healthcare is a space with a lot of deficiencies. Our team is on a mission to enable tech<br/> to solve these problems. Starting with the biggest clinical trials patient recruitment.</p> */}
          {/* <a href="#contact"><button className="bg-white border border-puke hover:bg-weird-yellow text-puke hover:text-white py-3 px-8 font-expanded font-medium text-xl mt-6 rounded-full">Contact us</button></a> */}
        {/* </section> */}
        <section id="contact" className="text-center flex flex-col">
          <p className="text-center text-2xl font-medium mt-12 md:mt-24">Contact Us</p>
          <ContactForm/>
        </section>
        <section className="my-12 w-full px-2 md:px-20">
          <a rel="noreferrer" href="https://www.google.com/maps/dir//Boston,+MA+02113,+USA/@42.3653936,-71.1373776,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89e3708939aab979:0xedffe7e92e98ae87!2m2!1d-71.0549768!2d42.3654231?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D" target="_blank"><button className="absolute right-16 md:right-32 bg-weird-yellow hover:bg-yellow-500 text-black py-2 px-4 md:px-8 font-expanded font-medium text-sm my-auto md:text-xl mt-6 rounded-full "><Directions/> Get Directions</button></a>
          <iframe title="map" className="w-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6018.789914809422!2d-71.05995730397356!3d42.36429248476977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3708939aab979%3A0xedffe7e92e98ae87!2sBoston%2C%20MA%2002113%2C%20USA!5e1!3m2!1sen!2seg!4v1727452376619!5m2!1sen!2seg" width="600" height="450" loading="lazy"></iframe>
        </section>
        <section className="flex flex-col">
          <p className="text-center text-2xl mb-12 font-medium">Social</p>
          <a href="https://www.linkedin.com/company/cureva-co" target="_blank" rel="noopener noreferrer">
          <LinkedIn className="text-center text-[#0072b1] w-44 h-32 mx-auto w-full"/>
          </a>
        </section>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
